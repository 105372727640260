import moment from 'moment-timezone';
import { TimeSettings } from '../../../vericlock_api/src/types/Settings';
import { DayOfWeek, DateString, TimeOfDay, TimeOfDayRange, TimezoneString, DayOfWeekToMomentNumericDay, getNumericDayOfWeek, PayrollSettings } from '../../../vericlock_api/src/types/Datetime';
export type { TimeOfDay, DateString, DayOfWeek, TimezoneString, TimeOfDayRange } from '../../../vericlock_api/src/types/Datetime';

import type { ScheduledReport } from '../../../vericlock_api/src/types/Reports';
import { clone } from 'lodash';
//re-export - to simplify conversion
export { DayOfWeekToMomentNumericDay, getNumericDayOfWeek, getDayOfWeekString, getDayOfWeekFullString,  } from '../../../vericlock_api/src/types/Datetime';

export const TimeOfDayMomentFormatString = 'HH:mm:ss'; 

//consider adding white space to the ends
//typeguard and check of a zone string
const TimeOfDayHHMM24 = /^\d\d:\d\d$/
export function isTimeOfDayHHMM(timeOfDayVal:string)
{ //todo: expand and validate against 00 -> 24, 00:59 ?
    return TimeOfDayHHMM24.test(timeOfDayVal)
}
const TimeOfDayRegex = /^(\d?\d):(\d\d)(:\d\d)?$/; //seconds can be there, but will generally be ignored and not a failure if missing
const TimeOfDay12HourRegex = /^(\d?\d):(\d\d)(:\d\d)?\s?(am|pm)$/i;
//typeguard function
export function isTimeOfDay(timeOfDayVal:any): timeOfDayVal is TimeOfDay {
    if(typeof timeOfDayVal === 'string' || timeOfDayVal instanceof String) //must be a string
    {
        let found = timeOfDayVal.match(TimeOfDayRegex);
        if(found)
        {
            let hours = parseInt(found[1]);
            let minutes = parseInt(found[2]);
            //00:00 to 24:00
            if(hours >= 0 && hours <= 24 && minutes >= 0 && minutes <= 59)
            {
                if(hours == 24 && minutes != 0)
                    return false;  //edge case, can't exceed 24:00
                return true;
            }
        }
    }

    return false;
}

export function isTimeOfDay12Hour(timeOfDayVal:any): boolean
{
    try {
        convert12HourTo24Hour(timeOfDayVal);
        return true;
    }
    catch (ex)
    {
        return false; 
    }
}
export function convert12HourTo24Hour(timeOfDayVal:any):string
{
    if(typeof timeOfDayVal === 'string' || timeOfDayVal instanceof String) //must be a string
    {
        let found = timeOfDayVal.match(TimeOfDay12HourRegex);
        if(found)
        {
            let hours = parseInt(found[1]);
            let minutes = parseInt(found[2]);
            let ampm = found[4].toLowerCase();
            let addition = ampm === 'pm' ? 12 : 0; //how much to add to hours to get 24 hour

            if(hours > 12)
                throw new Error('time of day, hours must be between 0 and 12 inclusive');
            if(minutes > 59)
                throw new Error('time of day, minutes must be between 0 and 59 inclusive');

            if (hours==12)  // 12 is actually 0 in 12 hours clock.
                hours = 0;
            
            //00:00 to 24:00
            return `${hours + addition}:${minutes.toString().padStart(2,"0")}`;
        }
        throw new Error('time of day must be in the format hh:mm(:ss) am|pm');
    }
    throw new Error('time of day must be a string');
}
//typeguard function
export function isTimeRange(rangeVal:any): rangeVal is TimeOfDayRange {
    if(!(rangeVal instanceof Array)) return false;  //must be an array
    if(rangeVal.length != 2) return false; //must contain two items

    if(!isTimeOfDay(rangeVal[0])) return false;
    if(!isTimeOfDay(rangeVal[1])) return false;

    return true;
}

export interface TimeChunk 
{
    hour: number,
    minute:number,
}

//turns two string TimeOfDays in a TimeRange array into two TimeChunks returned as an array to be destructured
export function parseTimeOfDayRangeToTimeChunks(timeRange:TimeOfDayRange):[TimeChunk,TimeChunk]
{
    return[
        parseTimeOfDayToTimeChunk(timeRange[0]),
        parseTimeOfDayToTimeChunk(timeRange[1])
    ];
}
export function parseTimeOfDayToTimeChunk(timeStr:TimeOfDay):TimeChunk
{
    let matches = timeStr.match(TimeOfDayRegex);
    if(!matches)
        throw new Error("Could not parse time of day in: " + timeStr);

    let hour1 = matches[1];
    let min1 = matches[2];
    return {
        hour: parseInt(hour1), 
        minute: parseInt(min1)
    };
}

export function parseTimeOfDayForDisplay(timeStr:TimeOfDay):string
{
    let td = parseTimeOfDayToTimeChunk(timeStr);
    let ampm = td.hour >= 12 ? 'PM' : 'AM';
    const hour = td.hour === 0 ? 12 : td.hour > 12 ? td.hour - 12 : td.hour;

    return hour + ':' + td.minute.toString().padStart(2, '0') + ' ' + ampm;

}

export function TimeChunkAfter(a:TimeChunk, b:TimeChunk):boolean
{
    if(a.hour > b.hour)
        return(true);
    else if(a.hour == b.hour && a.minute > b.minute)
        return(true);
    return(false);
}
export function TimeChunkBefore(a:TimeChunk, b:TimeChunk):boolean
{
    if(a.hour < b.hour)
        return(true);
    else if(a.hour == b.hour && a.minute < b.minute)
        return(true);
    return(false);
}
export function TimeOfDayAfter(a:TimeOfDay, b:TimeOfDay):boolean
{
    let [aChunk, bChunk] = parseTimeOfDayRangeToTimeChunks([a,b]);
    return(TimeChunkAfter(aChunk,bChunk));
}
export function TimeOfDayBefore(a:TimeOfDay, b:TimeOfDay):boolean
{
    let [aChunk, bChunk] = parseTimeOfDayRangeToTimeChunks([a,b]);
    return(TimeChunkBefore(aChunk,bChunk));
}

export function isDateString(a:string):a is DateString
{
    if(typeof(a) === 'string')
    {
        return moment(a,'YYYY-MM-DD').isValid();    
    }
    return false;
}
export function jsDateToDateString(d:Date)
{
    let month = (d.getUTCMonth()+1).toString().padStart(2,'0');
    let day = (d.getUTCDate()).toString().padStart(2,'0');
    
    
    return `${d.getUTCFullYear()}-${month}-${day}`;
}
export function isArrayOfDateStrings(a:DateString[], emptyOk:boolean): a is DateString[]
{
    if(!(a instanceof Array))
        return false; //not array

    if(a.length == 0)
        return emptyOk;

    for(let i=0; i < a.length; i++)
    {
        if(!isDateString(a[i])) //exit early
            return false;
    }
    //must be all valid dates (empty is allowed?)
    return true;
}

export const DaysOfTheWeekSunAt0:DayOfWeek[] = ['sun','mon','tue','wed','thu','fri','sat'];
export const DayOfWeekToNumericDayOfWeekSunAt0 = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
export function getOrderedDaysOfTheWeek(weekStartDay:DayOfWeek):DayOfWeek[]
{
    let weekStart = getNumericDayOfWeek(weekStartDay);
    if(weekStart < 0 || weekStart > 6)
        throw new Error('Invalid weekstart day number: ' + weekStart);
    
    let days:DayOfWeek[] = [];
    for(let i=0; i < 7;i ++)
    {
        days.push(DaysOfTheWeekSunAt0[weekStart]);
        weekStart++;
        if(weekStart >= 7)
            weekStart = 0; //restart at begining
    }
    return days;
}

type DayOfWeekArraySorter = (a:DayOfWeek, b:DayOfWeek) => number
const DayOfWeekSortersByWeekStart:Record<DayOfWeek,DayOfWeekArraySorter> = {
    'sun': makeDayOfWeekSorter('sun'),
    'mon': makeDayOfWeekSorter('mon'),
    'tue': makeDayOfWeekSorter('tue'),
    'wed': makeDayOfWeekSorter('wed'),
    'thu': makeDayOfWeekSorter('thu'),
    'fri': makeDayOfWeekSorter('fri'),
    'sat': makeDayOfWeekSorter('sat'),
}

function makeDayOfWeekSorter(start:DayOfWeek)
{
    const days = getOrderedDaysOfTheWeek(start);
    const lookup = days.reduce((prev,cur,index) => {
        prev[cur] = index;
        return prev;
    }, {} as Record<DayOfWeek,number>)

    //the index provides a natural sort comparison for ordering
    return (a:DayOfWeek, b:DayOfWeek) => {
        return lookup[a] - lookup[b];
    }
}
export function getDayOfWeekSorter(weekStart: DayOfWeek)
{
    return DayOfWeekSortersByWeekStart[weekStart]
}
export function convertDayOfWeekArrayToMap(days:DayOfWeek[])
{
    return days.reduce((prev,cur) => {
        prev[cur] = true;
        return prev;
    },{} as Record<DayOfWeek,boolean>);
}
export function convertDayOfWeekMapToArray(days:Record<DayOfWeek,boolean>)
{
    return (Object.keys(days) as DayOfWeek[]).filter(day => days[day]) as DayOfWeek[];
}

export function getDayOfWeekFromNumericDayOfWeek(dayNum:number):DayOfWeek
{
    let day = DaysOfTheWeekSunAt0[dayNum];
    if(!day)
        throw new Error(`Bad numeric day of week[${dayNum}] should be 0-6 inclusive`);
    return day;
}
export function getNumericDayOfWeekFromDayOfWeek(dayOfWeek:DayOfWeek):number 
{
    const dayNumber = DayOfWeekToNumericDayOfWeekSunAt0[dayOfWeek];
    if(typeof(dayNumber) === 'undefined')
        throw new Error('unknown dayOfWeek in weekStart: ' + dayOfWeek);
    return dayNumber;
}
//this function would 'memoize' nicely
// worst cas you have a 7bit entris in a table in ram - can afford it generically
let dayOfWeekMemoCache:{ [keyof:number]:DayOfWeek[] } = {};
export function makeDayOfWeekArrayFromBitfield(bitfield:number):DayOfWeek[]
{
    //check the cache and rturn
    if(dayOfWeekMemoCache[bitfield])
        return dayOfWeekMemoCache[bitfield];

    let weekdayArray:DayOfWeek[] = [];

    if(bitfield & 0b0000001) weekdayArray.push('sun');
    if(bitfield & 0b0000010) weekdayArray.push('mon');
    if(bitfield & 0b0000100) weekdayArray.push('tue');
    if(bitfield & 0b0001000) weekdayArray.push('wed');
    if(bitfield & 0b0010000) weekdayArray.push('thu');
    if(bitfield & 0b0100000) weekdayArray.push('fri');
    if(bitfield & 0b1000000) weekdayArray.push('sat'); 
    
    dayOfWeekMemoCache[bitfield] = weekdayArray;
    return weekdayArray;
}
export function makeDayOfWeekBitfield(daysOfWeek:DayOfWeek[]):number
{
    let weekdayBitField = 0; 
    daysOfWeek.forEach( (weekday:DayOfWeek) => {
        //shift a 1 left in the bitfield by the day of week (at 0 to 6)
        weekdayBitField |= (1 << getNumericDayOfWeek(weekday));
    });
    return weekdayBitField;
}

//typeguard function for day of week
export function isDayOfWeek(dayStr:string): dayStr is DayOfWeek {
    return typeof(DayOfWeekToMomentNumericDay[dayStr]) != 'undefined';
}

export function calcTest(input1:number, input2: number)
{
    return(input1 * input2);
}

export async function calcTestAsync(input1:number, input2: number)
{
    return new Promise((resolve) => {
        setTimeout(function(){
            resolve( input1 * input2 );
        });
    });
}

// export function parseDateTimeInCustomerTZ(customer:Customer, reportParams:FetchClockEventsForReportParams)
export function parseDateTimeInCustomerTZ(customer:any, timeString:string): moment.Moment
{
    let m = moment.tz(timeString, customer.getTimeZone()); //model converts db timezone correctly
    return m;
}

export function parseDateTimeInUTC(timeString:string): moment.Moment
{
    let m = moment.tz(timeString, 'UTC'); //model converts db timezone correctly
    return m;
}

//Takes a moment in any particular TZ, and rewinds it to the start of the week in that TZ
//based on the passed in week string
export function getStartOfWeek(randomDate:moment.Moment, startOfWeekDay: DayOfWeek): moment.Moment
{
    //the start of the week is startOfWeekDay in sun,mon,tue etc format

    //get the moment relevent numeric date of the week
    let numericDayOfWeek = DayOfWeekToMomentNumericDay[startOfWeekDay]; 

    //get the actual day of week of our candidate day
    let randomDateDayOfWeek = randomDate.day();

    let deltaDays = calcWeekStartMomentDelta(randomDateDayOfWeek,numericDayOfWeek);
    
    //clone, adjust by days, adjust time to the start of the day
    return(randomDate.clone().add(deltaDays,'days').startOf('day')); 
    // return(randomDate.clone().day(deltaDays).startOf('day')); 
    
}

export type WeekBoundary = { start: moment.Moment, end: moment.Moment };
export function getWeekStartEnd(randomDate:moment.Moment, startOfWeekDay: DayOfWeek): WeekBoundary
{
    let start = getStartOfWeek(randomDate,startOfWeekDay); //clones it
    let end = start.clone().add(1,'week');
    return {start, end}; 
}
export function getNextWeekStart(randomDate:moment.Moment, startOfWeekDay: DayOfWeek): moment.Moment
{
    let numericDayOfWeek = DayOfWeekToMomentNumericDay[startOfWeekDay]; 
    return getNextWeekStartWithNumericDayOfWeek(randomDate, numericDayOfWeek);
}
export function getNextWeekStartWithNumericDayOfWeek(randomDate:moment.Moment, numericDayOfWeek: number): moment.Moment
{
    //get the actual day of week of our candidate day
    let randomDateDayOfWeek = randomDate.day();

    let deltaDays = calcWeekStartMomentDelta(randomDateDayOfWeek,numericDayOfWeek)+7; //adding 7 to the number will delta us into next week
    
    //clone, adjust by days, adjust time to the start of the day
    return(randomDate.clone().add(deltaDays,'days').startOf('day')); 
}

function calcWeekStartMomentDelta(startDay:number,weekStart:number):number
{
    let delta = weekStart - startDay;
    if(delta > 0)
        return delta - 7;  //weekStart is AFTER the current day of week, so we need to go back to next weeks day
    
    //weekStart is before the current day in this week, just need to go back by the difference
    return delta;  
}

//todo - eventually draw upon a localization database + take a locale
export function dayOfWeekToString(dayOfWeek: DayOfWeek):string
{
    switch(dayOfWeek)
    {
        case 'mon': return 'Monday';
        case 'tue': return 'Tuesday';
        case 'wed': return 'Wednesday';
        case 'thu': return 'Thursday';
        case 'fri': return 'Friday';
        case 'sat': return 'Saturday';
        case 'sun': return 'Sunday';
    }

    throw new Error('Unknown dayOfWeek: ' + dayOfWeek);
}

export function rewindDateToWeekStart(weekStart:DayOfWeek, refDate:moment.Moment) {
    let startDayNum = getNumericDayOfWeekFromDayOfWeek(weekStart);
    
    let newDate = refDate.clone();
    if (refDate.day() !== startDayNum) {
        let daysOff = newDate.day() - startDayNum;
        if(daysOff < 0) {
            daysOff += 7;
        }
        newDate.subtract(daysOff,'days');
    }

    return newDate.startOf('day');
}
// export function rewindDateToPayrollStartForMonthlyPayroll(monthlyDayOffset:number, dateToRewind:moment.Moment):moment.Moment

//30th is day of month
//         30
//      29 30 31 1st
export function rewindDateToPayrollStartForMonthlyPayroll(monthlyDayOffset:number, dateToRewind:moment.Moment):moment.Moment
{
    let payrollStartTime = dateToRewind.clone();
    payrollStartTime.hours(0).minutes(0).seconds(0);

    let lastMonthPayrollStartTime = dateToRewind.clone().date(1).subtract(1,'months'); //clone, and bump back to first of last month
    let numDaysLastMonth = lastMonthPayrollStartTime.daysInMonth();
    let numDaysInThisMonth = dateToRewind.daysInMonth();
    let dayOfTheMonth = dateToRewind.date();

    if(monthlyDayOffset < 0)
    {
        //monthlyDayOffset is negative and relative to end of the month..-1 = last day of the month, -2 would be 2nd last day...
        let payrollDayThisMonth = numDaysInThisMonth + monthlyDayOffset + 1; //monthly offset of -1 means the last day, so we must increment by 1 after adding to the number of days in the month
        if(payrollDayThisMonth >= dayOfTheMonth)
        {
            payrollDayThisMonth = numDaysLastMonth + monthlyDayOffset + 1;
            payrollStartTime = lastMonthPayrollStartTime;
        }

        //ex. 15 vs 20 - start of this pay period will be payrollDayThisMonth + 1 day
        payrollStartTime.date(payrollDayThisMonth).add(1,'days');

    }
    else if(monthlyDayOffset > 0)
    {
        if(monthlyDayOffset < dayOfTheMonth)
        {
            payrollStartTime.date(monthlyDayOffset).add(1,'days');
        }
        else
        {
            payrollStartTime = lastMonthPayrollStartTime;
            if(monthlyDayOffset > numDaysLastMonth)
            {
                monthlyDayOffset = numDaysLastMonth;
            }
            payrollStartTime.date(monthlyDayOffset).add(1,'days');
        }
    }
    else
    {
        throw new Error("monthlyDayOffset should never be 0");
    }
    return(payrollStartTime);
}
// export function rewindDateToPayrollStart(payrollSettings, date, tz) {
// export function rewindDateToPayrollStart(payrollPeriod: PayrollPeriod, referenceLastDayOfPayroll: Date, considerationDate: Date, timezone: TimezoneString)
export function rewindDateToPayrollStart(payrollSettings: PayrollSettings, considerationDate: moment.Moment, timezone: TimezoneString)
{
    var payrollStartTime:null|moment.Moment = null;

    function getFirstDayOfPayroll(dateStrYYYYMMDD:string)
    {
        let referenceLastDayOfPayrollMoment = moment.tz(dateStrYYYYMMDD, 'YYYY-MM-DD', true, timezone);
        if(!referenceLastDayOfPayrollMoment.isValid())
            throw new Error('Invalid payroll date['+dateStrYYYYMMDD+'] - must be set as YYYY-MM-DD');

        return(referenceLastDayOfPayrollMoment.clone().add(1, 'days')); //add 1 day to make it the start of a pay period
    }
    let considerationMoment = considerationDate.clone().tz(timezone).hours(0).minutes(0).seconds(0).milliseconds(0); 
    var daysDiff;
    var periodStartDiff;
    var day;
    var maxDays;
    var tmpDate;
    if(payrollSettings.payrollPeriod === 'weekly') {
        payrollStartTime = rewindDateToWeekStart(getDayOfWeekFromNumericDayOfWeek(getFirstDayOfPayroll(payrollSettings.recentPayrollDate).day()), considerationMoment);
    }
    else if(payrollSettings.payrollPeriod === 'biweekly') {
        daysDiff = considerationMoment.diff(getFirstDayOfPayroll(payrollSettings.recentPayrollDate), 'days');
        periodStartDiff = daysDiff % 14; //difference between ref date (could be 300 days in past) and consideration date modulo 14 tells us if its before or after, and we can add 14 to get to the correct diff from the reference date
        // if(daysDiff < 0) {  //if the re
        if(periodStartDiff < 0) {  //if the re
            periodStartDiff += 14;
        }
        payrollStartTime = considerationMoment.clone();
        payrollStartTime.subtract(periodStartDiff, 'days');
    }
    else if(payrollSettings.payrollPeriod === 'semimonthly') {
        payrollStartTime = considerationMoment.clone();
        day = considerationMoment.date();
        //toString - old old value might be a string due to poor adherence to types and then serialization as a string
        var firstHalf = payrollSettings.semiMonthlyFirstHalf;
        var lastHalf = payrollSettings.semiMonthlyLastHalf;
        var thisMonthLastHalf = lastHalf;
        tmpDate = payrollStartTime.clone();

        if(lastHalf === -1) {
            thisMonthLastHalf = tmpDate.endOf('month').date();
        }

        if(day < (firstHalf + 1)){
            payrollStartTime.date(1);
            payrollStartTime.subtract(1, 'months');
            tmpDate = payrollStartTime.clone();
            maxDays = tmpDate.endOf('month').date();
            if (lastHalf === -1) {	//last half is set to end of the month
                lastHalf = maxDays;
            }
            //if lastHalf is set to a day that doesnt exist in the month, just set it to the end of the month
            if(lastHalf > maxDays) {
                // payrollStartTime.subtract(1, 'months');
                payrollStartTime.date(maxDays);
            }
            else {
                payrollStartTime.date(lastHalf);
            }
            payrollStartTime.add(1, 'days');
        }
        else if(day >= (thisMonthLastHalf + 1)) {
            payrollStartTime.date(thisMonthLastHalf);
            payrollStartTime.add(1, 'days');
        }
        else {
            payrollStartTime.date(firstHalf);
            payrollStartTime.add(1, 'days');
        }
    }
    else if(payrollSettings.payrollPeriod === '4weeks') {
        payrollStartTime = getFirstDayOfPayroll(payrollSettings.recentPayrollDate);
        daysDiff = considerationMoment.diff(payrollStartTime, 'days');
        periodStartDiff = daysDiff % 28;
        if(periodStartDiff < 0) {
            periodStartDiff += 28;
        }
        payrollStartTime = considerationMoment.clone();
        payrollStartTime.subtract(periodStartDiff, 'days');
    }
    else if(payrollSettings.payrollPeriod === 'monthly')
    {
        payrollStartTime = rewindDateToPayrollStartForMonthlyPayroll(payrollSettings.monthlyDay, considerationMoment);
    }
    else //can be none - bad!
    {
        let payperiodSetToNoneErr = new Error('payroll settings set to none - must be set');       
        payperiodSetToNoneErr.name = 'PayPeriodNone';
        throw payperiodSetToNoneErr;
    }
    payrollStartTime.hours(0);
    payrollStartTime.minutes(0);
    payrollStartTime.seconds(0);
    return payrollStartTime;
}

export function getPreviousPayPeriodBlock(
    payrollSettings:PayrollSettings, 
    considerationDate: moment.Moment,
    timezone: TimezoneString, 
    numPeriods: number
):{
    start: moment.Moment
    end: moment.Moment
}
{
    //sanity check/protection 
    const limitPayPeriods = 200;
    if(numPeriods > limitPayPeriods)
        throw new Error(`If you need to report on more than ${limitPayPeriods} pay periods, consider using a specific date range yourself, or please contact support to discuss your needs`);

    const end = rewindDateToPayrollStart(payrollSettings, considerationDate, timezone).subtract(1, 'seconds');
    let start = rewindDateToPayrollStart(payrollSettings, end, timezone);

    //now loop and get the next start and next start for each additional period beyond 1
    for(let i=0; i < numPeriods-1; i++) //-1 because 
    {
        //subtract so start is in the previous pay period, and use it as a reference date to rewind again 
        start = rewindDateToPayrollStart(payrollSettings, start.clone().subtract(1, 'hour'), timezone);
    }

    return { start, end };
}

//for a given date 'considerationDate' and pay period settings (type and a 'recent' payroll date from settings) 
// calulate the start and end payroll dates
const USE_OLD_WAY = false;
export function getPayrollPeriodBounds(payrollSettings:PayrollSettings, considerationDate: moment.Moment, timezone: TimezoneString):{
    start: moment.Moment
    end: moment.Moment
}
{   
    var payrollStart = rewindDateToPayrollStart(payrollSettings, considerationDate, timezone);
    var payrollEnd = payrollStart.clone();

    var maxDays;
    var startDay;
    var tmpDate; // Used for calculating month ends.
    if(payrollSettings.payrollPeriod === 'weekly' || payrollSettings.payrollPeriod === 'none') {
        payrollEnd.add(1, 'weeks');
        payrollEnd.subtract(1, 'seconds');
    }
    else if(payrollSettings.payrollPeriod === 'biweekly') {
        payrollEnd.add(2, 'weeks');
        payrollEnd.subtract(1, 'seconds');
    }
    else if(payrollSettings.payrollPeriod === 'semimonthly') {
        var firstHalf = payrollSettings.semiMonthlyFirstHalf;
        var lastHalf = payrollSettings.semiMonthlyLastHalf;
        startDay = payrollStart.date();
        var thisMonthLastHalf = lastHalf;
        tmpDate = payrollStart.clone();
        if(lastHalf === -1) {
            thisMonthLastHalf = tmpDate.endOf('month').date();
        }

        if(startDay < firstHalf) {
            payrollEnd.date(firstHalf);
            payrollEnd.add(1, 'days');
            payrollEnd.subtract(1, 'seconds');
        }
        else if(startDay >= thisMonthLastHalf) {
            payrollEnd.add(1, 'months');
            payrollEnd.date(firstHalf);
            payrollEnd.add(1, 'days');
            payrollEnd.subtract(1, 'seconds');
        }
        else {
            payrollEnd.month(payrollStart.month());
            payrollEnd.date(1);
            tmpDate = payrollEnd.clone();
            maxDays = tmpDate.endOf('month').date();
            if(lastHalf === -1) {	//last half is set to end of the month
                lastHalf = maxDays;
            }
            if(lastHalf > maxDays) {
                payrollEnd.date(maxDays);
            }
            else {
                payrollEnd.date(lastHalf);
            }
            payrollEnd.add(1, 'days');
            payrollEnd.subtract(1, 'seconds');
        }
    }
    else if(payrollSettings.payrollPeriod === '4weeks') {
        payrollEnd.add(4, 'weeks');
        payrollEnd.subtract(1, 'seconds');
    }
    else if(payrollSettings.payrollPeriod === 'monthly') {
        if(!USE_OLD_WAY)
        {
            startDay = payrollStart.date();
            if(payrollSettings.monthlyDay < 0)
            {
                //-1,-2 relative to end of the month
                let endOfPeriod = payrollStart.clone().endOf('month').hours(23).minutes(59).seconds(59).millisecond(0);
                //+1 to the negative offset, because -1 == LAST day of the month, so we do not want to adjust at all for it
                //-2 == 1 day before last day, so we need to subtract 1 day from month end, and so on
                payrollEnd = endOfPeriod.clone().add(payrollSettings.monthlyDay+1,'day');
                if(payrollEnd.isBefore(payrollStart))
                {
                    payrollEnd = payrollStart.clone().add(1,'month').endOf('month').hours(23).minutes(59).seconds(59).millisecond(0).add(payrollSettings.monthlyDay+1, 'day');
                }
            }
            else if(payrollSettings.monthlyDay > 0)
            {
                //specific date of the month
                if(startDay > payrollSettings.monthlyDay) //31 vs end date of 30 - end day is on the .monthlyDay in the next month
                {
                    //advance start date to start of month, add 1 month, then set the day of month to the monthly date of payroll OR the end of the month
                    payrollEnd = payrollStart.clone().startOf('month').add(1,'month');
                }
                else //startDay <= payrollSettings.monthlyDay
                {
                    payrollEnd = payrollStart.clone(); //pay end is in the same month
                }
                //in either case, we set the end date to the end of the month, or the day of the month specified
                if(payrollEnd.daysInMonth() <= payrollSettings.monthlyDay)
                    payrollEnd.date(payrollEnd.daysInMonth()); //set to end of month
                else
                    payrollEnd.date(payrollSettings.monthlyDay);            
            }
            else
                throw new Error('cannot have monthlyDay=0');

            payrollEnd.hour(23).minute(59).second(59).millisecond(0); //advance end to exact end of the period
        }
        else
        {
            startDay = payrollStart.date();
            payrollEnd.add(1, 'months');
            payrollEnd.date(1);
            tmpDate = payrollEnd.clone();
            maxDays = tmpDate.endOf('month').date();
            if(startDay > maxDays) {
                payrollEnd.date(maxDays);
            }
            else {
                payrollEnd.date(startDay);
            }
            payrollEnd.subtract(1, 'seconds');
        }
    }

    return {
        start: payrollStart,
        end: payrollEnd
    };
}

//takes a reference date (typically now), timezone and reference last day of payroll
//and based on the ScheduledReport's settings produces the date the report
//will be run next
interface ScheduledReportSendDateCalculationParams
{
    payrollSettings: PayrollSettings,
    // payrollDate: Date|null,
    // payrollPeriod: PayrollPeriod, 
    // referenceLastDayOfPayroll: Date,
    timezone: TimezoneString,
    scheduledReportSettings: Pick<ScheduledReport, 
        'coverage'|
        'sendTimeOfDay'|
        'sendDayOfWeek'|
        'sendDelayDays'>    
}
export function calculateScheduledReportSendDate(params:ScheduledReportSendDateCalculationParams, relativeDate:null|moment.Moment):moment.Moment
{
    let referenceDate = !relativeDate ? moment().tz(params.timezone).seconds(0).milliseconds(0) : relativeDate;

    //parse send time of day and put report's time of day into a moment date obj - so 
    let sendTimeOfDay = parseTimeOfDayToTimeChunk(params.scheduledReportSettings.sendTimeOfDay);
    let nextNotifyDate = referenceDate.clone().hour(sendTimeOfDay.hour).minute(sendTimeOfDay.minute);

    switch(params.scheduledReportSettings.coverage)
    {
        //daily report types - day of week, days after not used
        case 'currentDay':
        case 'previousDay':
            if (referenceDate.isAfter(nextNotifyDate)) { 
                // Advance +1 day and schedule it for tomorrow if the constructed send time (nowDatetime + sendtime of day) is BEFORE 'now'
                nextNotifyDate.add(1, 'days'); //already has time of day applied
            }
            break;
        //every pay period, daysAfter - day of week not used
        case 'previousPayPeriod':
        {
            if(!params.scheduledReportSettings.sendDelayDays)
                throw new Error('scheduledReportSettings.sendDelayDays must be set')

            //pay period types that will have a recet pay period - check validity of the date + setting
            if(params.payrollSettings.payrollPeriod == 'biweekly' || params.payrollSettings.payrollPeriod == 'weekly' || params.payrollSettings.payrollPeriod == '4weeks')
            { 
                if(!params.payrollSettings.recentPayrollDate)
                {
                    throw new Error('Recent payroll date is not valid - correct in payroll settings'); //ug - this should be validated higher up
                }
            }
            else if(params.payrollSettings.payrollPeriod == 'none') //UI no longer allows this - defaults to week
            {
                throw new Error('You must first configure a payroll period in payroll settings');
            }           
            // const payrollSettings = req.vericlock.customer.settings.get(CustomerSettings.Settings.PayrollSettings);
            
            // Roll back 1 period and calculate the expected date. If the scheduled date is still in the future, use that.
            let {start: previousPayrollEnd, end: nextPayrollDate } = getPayrollPeriodBounds(params.payrollSettings, referenceDate, params.timezone);

            previousPayrollEnd.subtract(1,'second'); //rewindDateToPayrollStart(payrollSettings, nextNotifyDate, tz).subtract(1, 'seconds');
            // let nextPayrollDate = DBTime.forwardDateToPayrollEnd(payrollSettings, nextNotifyDate, tz);

            nextNotifyDate.year(previousPayrollEnd.year());
            nextNotifyDate.month(previousPayrollEnd.month());
            nextNotifyDate.date(previousPayrollEnd.date());
            nextNotifyDate.add(params.scheduledReportSettings.sendDelayDays, 'days'); //min + 1 - which will take it to the ref dat, and it still might be after 'now'

            if (referenceDate.isAfter(nextNotifyDate)) {
                // Already passed the scheduled date. Bump the next notify date 1 more payroll period.
                nextNotifyDate.year(nextPayrollDate.year());
                nextNotifyDate.month(nextPayrollDate.month());
                nextNotifyDate.date(nextPayrollDate.date());
                nextNotifyDate.add(params.scheduledReportSettings.sendDelayDays, 'days');
            }   
            break;
        }
        case 'currentWeek':
        case 'previousWeek':
        case 'previousTwoWeeks':
        case 'currentPayPeriod': //produced weekly currently I believe
            if(!params.scheduledReportSettings.sendDayOfWeek) //day of week must be set for these
                throw new Error('scheduledReportSettings.sendDayOfWeek must be set')

            while (nextNotifyDate.format('ddd').toLowerCase() !== params.scheduledReportSettings.sendDayOfWeek) {
                // Fast forward to the day of notification.
                nextNotifyDate.add(1, 'days');
            }
            
            while (referenceDate.isAfter(nextNotifyDate)) {
                // Need to fast forward the notify date.
                // Advance by 1 week.
                nextNotifyDate.add(7, 'days');
            }
            break;
        case 'jobCost':
        default:
            throw new Error('Unsupported report coverage: ' + params.scheduledReportSettings.coverage);
    }

    return nextNotifyDate;
}


//start/end are inclusive of the period
export function getAveragingPeriodDateRange(aBeginDate:DateString, relativeDate: DateString, weeksPerPeriod: number, periodDelta: number):{
    start: DateString,
    end: DateString
}
{
    let periodBeginMoment = moment.utc(aBeginDate);
    let relativeMoment = moment.utc(relativeDate);

    //Days since the begin date until the relative(now) date
    //divided by the number of periods (in days) floored, gets us the closest period starting
    //to now or previous start
    let daysSinceBegin = relativeMoment.diff(periodBeginMoment, 'days');

    //allow us to jump directly to the desired period in the calculation
    daysSinceBegin += (periodDelta *  weeksPerPeriod * 7);

    let periodsFloored = Math.floor(daysSinceBegin/(weeksPerPeriod*7));
    // let remainder = daysSinceBegin % weeksPerPeriod*7; //remainder == 0 means it lands on the relativeDate

    let periodBegin = periodBeginMoment.clone().add(periodsFloored*weeksPerPeriod*7, 'days'); 
    
    return {
        start: periodBegin.format('YYYY-MM-DD'),
        //advance to the final day in the period by adding days in the period minus 1
        end: periodBegin.clone().add(weeksPerPeriod*7-1, 'days').format('YYYY-MM-DD')
    }
}
//jan 1, 2015 -> thursday
//jan 1, 2016 -> friday
//jan 1, 2017 -> sunday
//jan 1, 2018 -> Monday
//jan 1, 2019 -> tuesday
//jan 1, 2020 -> wednesday
//jan 1, 2021 -> friday
//jan 1, 2022 -> saturday 

///@ts-ignore
const weekNumberTest = [
    [ 'sun', '2019-12-29', ],
    [ 'sun', '2019-12-30', ],
    [ 'sun', '2019-12-31', ],
    [ 'sun', '2020-01-01', 1], //wed
    [ 'sun', '2020-01-02', 1],
    [ 'sun', '2020-01-03', 1],
    [ 'sun', '2020-01-04', 1],
    [ 'sun', '2020-01-05', 2],
    [ 'sun', '2020-01-06', 2],
    [ 'sun', '2020-01-07', 2],
];

// function getWeekNumber(mom:moment.Moment, weekStart:DayOfWeek):number
// {
//     // let d = new Date();
//     // d.getDay()
//     mom.dayOfYear();
// }
 
function makeDateRangeDisplay(start:moment.Moment, end:moment.Moment, type:'week'|'month'|'payperiod'):{
    display:string,
    key:string
}
{
    let display = start.format('MMM D') + ' to ' + end.format('MMM D');
    let key = start.format('YYYYMMDD') + '-' + end.format('YYYYMMDD');

    switch(type)
    {
        case 'week':
            key += '_w';
            break;
        case 'month':
            key += '_m';
            break;
        case 'payperiod':
            key += '_pp';
            break;
        default:
            key += '_unknown'; 
            break;
    }

    // let display = weekMom.format('MMM D');
    // let key = weekMom.year() + '-' + display;
    // displa = 'Week of ' + display;
    return {
        display,
        key
    }
}
export function DateToWeekStr(refDate:moment.Moment|Date, weekStart:DayOfWeek, timezone:TimezoneString):{
    display: string,
    key: string
}
{
    let mom = moment(refDate).tz(timezone);
    //If mess with rendering, the unique key must stay unique across time so group-by continues to work
    // let display = mom.format('YYYY') + '-' + getWeekNumber(mom, weekStart).toString().padStart(2,'0');
    let start = rewindDateToWeekStart(weekStart, mom);
    let end = start.clone().add(6, 'days');
    
    return makeDateRangeDisplay(start,end, 'week');

}
export function DateToMonthStr(refDate:moment.Moment|Date, timezone:TimezoneString):{
    display: string,
    key: string
}
{
    let start = moment(refDate).tz(timezone).startOf('month');
    let end = start.clone().endOf('month');

    return makeDateRangeDisplay(start,end, 'month');
    // let display = start.format('MMM D') + ' to ' + end.format('MMM D');
    // let key = start.format('YYYYMMDD') + '-' + end.format('YYYYMMDD') + '_w';

    //If mess with rendering, the unique key must stay unique across time so group-by continues to work
    // let display = mom.format('MMM YYYY');
    // return {
    //     display,
    //     key: display + '_mo'

}
export function DateToPayPeriodStr(refDate:moment.Moment|Date, payrollSettings: PayrollSettings, timezone:TimezoneString):{
    display: string,
    key: string
}
{
    let { start, end } = getPayrollPeriodBounds(payrollSettings, moment(refDate), timezone);

    return makeDateRangeDisplay(start,end, 'payperiod');
}

type AveragingTest =
{
    avgBeginDate: DateString,
    weeksPerPeriod: number,
    relativeDate: DateString,
    periodDelta: number,
    expected: {
        start: DateString,
        end: DateString
    }
}
let averagingRuleTestMatrix:AveragingTest[] = [
    {
        avgBeginDate: '2020-09-07', //Mon, sep 7 2020
        weeksPerPeriod: 2,
        relativeDate: '2020-09-06', 
        periodDelta: 0,
        expected: {
            start: '2020-08-24',
            end: '2020-09-06'
        }
    },
    {
        avgBeginDate: '2020-09-07', //Mon, sep 7 2020
        weeksPerPeriod: 2,
        relativeDate: '2020-09-07', 
        periodDelta: 0,
        expected: {
            start: '2020-09-07',
            end: '2020-09-20'
        }
    },
];
(function testAveragingDeltaCalcs()
{
    averagingRuleTestMatrix.forEach(test => {
        let results = getAveragingPeriodDateRange(test.avgBeginDate, test.relativeDate, test.weeksPerPeriod, test.periodDelta);
        if(results.start != test.expected.start)
        {
            console.error('averaging test error: expected|got');
            console.log(test);
            console.log(results)
        }
    });
})(); //run it

function testGetWeekStart()
{
    const WeekStartTestData:number[][] = 
    [
        //if current day is X and start of week is Y then we should pass in Z
        [0,0,0],         //already on the day - trivial reject
        [0,1,-6],        //today is sunday, week start is monday, gotta go maximal distance back
        [0,2,-5],
        [0,3,-4],
        [0,4,-3],
        [0,5,-2],
        [0,6,-1],

        [1,0,-1],   //back 1 day      
        [1,1,0],    //already on the day - trivial reject
        [1,2,-6],   //back 6 days from monday to tuesday
        [1,3,-5],
        [1,4,-4],
        [1,5,-3],
        [1,6,-2],

        [2,0,-2],   //back 2 days      
        [2,1,-1],   //back 1 days
        [2,2,0],    //already on the day - trivial reject
        [2,3,-6],   //back 6 days from tuesday to wednesday
        [2,4,-5],
        [2,5,-4],
        [2,6,-3],

        [3,0,-3],   //back 3 days - wed to sun
        [3,1,-2],   //back 2 days - wed to mon
        [3,2,-1],   //back 1 day - wed to tue
        [3,3,0],    //same day, trivial reject    
        [3,4,-6],
        [3,5,-5],
        [3,6,-4],

        [4,0,-4],
        [4,1,-3],
        [4,2,-2],
        [4,3,-1],
        [4,4,0],
        [4,5,-6],
        [4,6,-5],

        [5,0,-5],
        [5,1,-4],
        [5,2,-3],
        [5,3,-2],
        [5,4,-1],
        [5,5,0],
        [5,6,-6],

        [6,0,-6],
        [6,1,-5],
        [6,2,-4],
        [6,3,-3],
        [6,4,-2],
        [6,5,-1],
        [6,6,0],
        //0     0       0    
        //0     1       -6   
        //0     2       -5
        //0     3       -4  //sunday to wed - gotta go back 4 days
        //0     6       -1  //today is sunday, week start is saturday - rewind 1 day

        //1     0       -1    //today is monday, week start is sunday, gotta go back 1 day
        //1     1       0       
        //1     2       -6
        //1     6
        //2
        //3
        //4
        //5
        //6
    ];
    for(let i=0; i < WeekStartTestData.length; i++)
    {
        let test = WeekStartTestData[i];
        let result = calcWeekStartMomentDelta(test[0],test[1]);
        if(result != test[2])
        {
            console.error(`test WeekStartTestData[${i}] failed, expected(${test[2]}) got(${result})`);
        }
    }
}

type OldEventBlockOptions = {
    nowMoment: moment.Moment,
    oldEventEditBlock:TimeSettings['oldEventEditBlock'], 
    timezone: TimezoneString,
    payrollSettings: PayrollSettings,
    eventStart: Date
}
export function dateIsOlderThanThresholdDateForClockBlocking(options: OldEventBlockOptions)
{
    const { timezone, eventStart } = options;

    let editRanges = calculateThresholdDateForBlockingOldEventEdits(options);
    if(!editRanges)
        return false; 

    const compareDate = moment.tz(eventStart, timezone);
    if(compareDate.isBefore(editRanges.thresholdDate))
        return true;
    return false;
}

export function calculateThresholdDateForBlockingOldEventEdits(options:Omit<OldEventBlockOptions, 'eventStart'>): {
    rangeStart: moment.Moment|undefined,
    rangeEnd: moment.Moment|undefined,
    editCutOff: moment.Moment|undefined,
    thresholdDate: moment.Moment|undefined,
}|null
{
    const { nowMoment, oldEventEditBlock, timezone, payrollSettings } = options;

    let endOfPriorPeriod:moment.Moment|undefined;
    let startOfPriorPeriod:moment.Moment|undefined;
    let plusOffset:moment.Moment|undefined;
    let thresholdDate:moment.Moment|undefined;
    switch(oldEventEditBlock.type)
    {
        case 'none':
            return null; 
        case 'priorMonth':
            {
                endOfPriorPeriod = nowMoment.clone().hour(0).minute(0).second(0).millisecond(0).startOf('month');
                startOfPriorPeriod = endOfPriorPeriod.clone().subtract(1, 'month');
                plusOffset = endOfPriorPeriod.clone().add( oldEventEditBlock.dayOffset, 'day' );
                if(nowMoment.isBefore(plusOffset))
                    thresholdDate = startOfPriorPeriod;
                else
                    thresholdDate = endOfPriorPeriod;
            }
            break;
        case 'priorWeek':
            {
                endOfPriorPeriod = rewindDateToWeekStart(payrollSettings.weekStart, nowMoment.clone());
                startOfPriorPeriod = endOfPriorPeriod.clone().subtract(1, 'week');
                plusOffset = endOfPriorPeriod.clone().add( oldEventEditBlock.dayOffset, 'day' );
                if(nowMoment.isBefore(plusOffset))
                    thresholdDate = startOfPriorPeriod;
                else
                    thresholdDate = endOfPriorPeriod;

            }            
            break;
        case 'priorPayPeriod':
            {
                endOfPriorPeriod = rewindDateToPayrollStart(payrollSettings, nowMoment.clone(), timezone);
                startOfPriorPeriod = rewindDateToPayrollStart(payrollSettings, endOfPriorPeriod.clone().subtract(1,'day'), timezone); 
                plusOffset = endOfPriorPeriod.clone().add( oldEventEditBlock.dayOffset, 'day' );
                if(nowMoment.isBefore(plusOffset))
                    thresholdDate = startOfPriorPeriod;
                else
                    thresholdDate = endOfPriorPeriod;
            } 
            break;
        case 'specificDate':
            thresholdDate = moment.tz(oldEventEditBlock.specificDate, timezone).startOf('day').add(1, 'day');
            break;
        case 'hoursAfterStart':
            //subtract threshold hours from now - it will be compared to the event start
            thresholdDate = nowMoment.clone().subtract(oldEventEditBlock.hoursAfterStart, 'hours');
            break;
        default:
            console.error('unknown old event block type: ' + oldEventEditBlock.type);                    
            thresholdDate = nowMoment.clone();
            break;
    }
    return {
        rangeStart:startOfPriorPeriod,
        rangeEnd: endOfPriorPeriod,
        editCutOff: plusOffset,
        thresholdDate
    }
}

export function getMinutesInDay(momentObj:moment.Moment):{
    minutes: number,
    startOfDay: moment.Moment,
    startOfNextDay: moment.Moment
}
{
    let startMoment = momentObj.startOf('day');
    let endMoment = startMoment.clone().add(1,'day');
    return ({
        minutes: endMoment.diff(startMoment,'minutes'),
        startOfDay: startMoment,
        startOfNextDay: endMoment
    });
}

//shallow clones defaultValue into each bucket
//{
//  '2020-01-01': clone(defaultValue)
//  '2020-01-02': clone(defaultValue)
//  '2020-01-03': clone(defaultValue)
// }
//starDate/endDate in correct tz - expected
export function constructDateRangeBuckets<T>(startDate:moment.Moment, endDate:moment.Moment, defaultValue: T):Record<DateString,T>
{
    //inclusive
    let rangeBucket:Record<DateString,T> = {};
    let dateStr;
    do {
        dateStr = startDate.format('YYYY-MM-DD');
        rangeBucket[dateStr] = clone(defaultValue);
    } while(dateStr != endDate.format('YYYY-MM-DD'));

    return rangeBucket;
}

//side effect - add to test suite!
testGetWeekStart();